import { BrowserRouter, Switch } from "react-router-dom";
import PageNotFound from "./components/404/PageNotFound";
import EmptyLayout from "./layouts/EmptyLayout";
import LoginLayout from "./layouts/LoginLayout";
import OthersPages from "./layouts/OthersPages";
import Login from "./pages/Authentification/Login";
import Calendrier from "./pages/Calendrier/Calendrier";
import ListeCatalogue from "./pages/catalogue/ListeCatalogue";
import CheckUser from "./pages/checkUser/CheckUser";
import CommandeGMS from "./pages/commandes/CommandeGMS";
import DetailsCMD from "./pages/commandes/DetailsCMD";
import AddConge from "./pages/conge/AddConge";
import CongeInterface from "./pages/conge/CongeInterface";
import MainPage from "./pages/Dashboard/MainPage";
import ListeDetailsTournees from "./pages/Dashboard/Maps/ListeMagasins";
import DetailsMerch from "./pages/detailsMerch/DetailsMerch";
import Documentations from "./pages/documentations/Documentations";
import AddNotes from "./pages/envoiNotes/AddNotes";
import CreationNote from "./pages/envoiNotes/CreationNote";
import EditNote from "./pages/envoiNotes/EditNote";
import AutoEvaluation2 from "./pages/Evaluation/auto_evaluation/AutoEvaluation2";
import EvaluationInterface from "./pages/Evaluation/EvaluationInterface";
import ListeEvaluation from "./pages/Evaluation/ListeEvaluation";
import ResultatAnnuelle from "./pages/Evaluation/ResultatAnnuelle";
import ListeImages from "./pages/gallerie/ListeImages";
import ValidationGratuite from "./pages/gratuites/ValidationGratuite";
import AjouterPdv from "./pages/Magasins/AjouterPDV/AjouterPdv";
import AjouterConventiion from "./pages/Magasins/Convention/AjouterConventiion";
import AjouterDesConventions from "./pages/Magasins/Convention/AjouterDesConventions";
import DetailsConventions from "./pages/Magasins/Convention/DetailsConventions";
import EditConventions from "./pages/Magasins/Convention/EditConventions";
import ListeConventions from "./pages/Magasins/Convention/ListeConventions";
import PlanningCategorie from "./pages/Magasins/detailsCategorie/PlanningCategorie";
import DetailsPdv from "./pages/Magasins/DetailsPdv/DetailsPdv";
import ListeGroupePDV from "./pages/Magasins/GroupePdv/ListeGroupePDV";
import HistoriqueMagasins from "./pages/Magasins/Historique/HistoriqueMagasins";
import InterfaceHistoriqueImages from "./pages/Magasins/Historique/InterfaceHistoriqueImages";
import ListeMagasins from "./pages/Magasins/ListeMagasins";
import ModifierPDV from "./pages/Magasins/ModifierPDV/ModifierPDV";
import ConfigurationMagasins from "./pages/Magasins/Parrametrage/ConfigurationMagasins";
import ListeNotes from "./pages/notes/ListeNotes";
import PartLineaire from "./pages/PartLineaire/PartLineaire";
import PartLineaireAnnuel from "./pages/PartLineaire/PartLineaireAnnuel";
import PartLineaireWeek from "./pages/PartLineaire/PartLineaireWeek";
import PhotoRayons from "./pages/PhotoRayons/PhotoRayons";
import AjouterProduits from "./pages/Produits/AddProduit/AjouterProduits";
import ListeCadeaux from "./pages/Produits/cadeaux/ListeCadeaux";
import ConfigurationProduits from "./pages/Produits/ConfigurationProduits";
import AjouterConventionProduits from "./pages/Produits/ConventionProduit/AjouterConventionProduits";
import PageDLC from "./pages/Produits/DLC/pageDLC";
import PageDN from "./pages/Produits/DN/pageDN";
import ListeProduits from "./pages/Produits/ListeProduits";
import CodeMETI from "./pages/Produits/METI/CodeMETI";
import ModifierProduits from "./pages/Produits/ModifierProduits/ModifierProduits";
import ListePromotions from "./pages/Promotions/ListePromotions";
import Promotions from "./pages/Promotions/Promotions";
import RapportDN from "./pages/Rapport/DN/RapportDN";
import RapportContact from "./pages/Rapport/GestionStock/RapportContact";
import RapportGestionStock from "./pages/Rapport/GestionStock/RapportGestionStock";
import KPISales from "./pages/Rapport/kpisales/KPISales";
import ListeRapports from "./pages/Rapport/ListeRapports";
import EditRapports from "./pages/Rapport/parametrageRapports/EditRapports";
import RapportPartLineaire from "./pages/Rapport/PartLineaire/RapportPartLineaire";
import RapportTableauPL from "./pages/Rapport/PartLineaire/RapportTableauPL";
import RapportGestion from "./pages/Rapport/RapportDashboard/RapportGestion";
import ListeGraphe from "./pages/Rapport/rapportGraphe/ListeGraphe";
import DetailsMagasins from "./pages/Rapport/RapportJournalier/DetailsMagasins";
import ListeProduitsRuptures from "./pages/Rapport/RapportJournalier/ListeProduitsRuptures";
import RapportJournalier from "./pages/Rapport/RapportJournalier/RapportJournalier";
import RapportVP from "./pages/Rapport/RapportVisitePointages/RapportVP";
import RapportGestionGlobale from "./pages/Rapport/rapport_16/RapportGestionGlobale";
import RapportMerchandiseur from "./pages/Rapport/rapport_merchandiseur/RapportMerchandiseur";
import RapportFlower from "./pages/Rapport/RupturesRapp/rapportFlower";
//import Rupture1 from "./pages/Rapport/RupturesRapp/Rupture1";
import RuptureFlower1 from "./pages/Rapport/RupturesRapp/RuptureFlower1";
import RapportStockIssues from "./pages/Rapport/stockIssues/RapportStockIssues";
import SuiviPL from "./pages/Rapport/Suivi/SuiviPL";
import SuiviReleveprix from "./pages/Rapport/Suivi/SuiviReleveprix";
import SuiviRupture from "./pages/Rapport/Suivi/SuiviRupture";
//import Rupture2 from "./pages/Rapport/RupturesRapp/Rupture2";
import LocalisationPointage from "./pages/Rapport/SuiviPointage/LocalisationPointage";
import ParamétragePointage from "./pages/Rapport/SuiviPointage/ParamétragePointage";
import SuiviPointage from "./pages/Rapport/SuiviPointage/SuiviPointage";
import RapportSuiviTournee from "./pages/Rapport/SuiviTournee/RapportSuiviTournee";
import RapportVente from "./pages/Rapport/Vente/RapportVente";
import RapportVisiteMagasins from "./pages/Rapport/VisiteMagasin/RapportVisiteMagasins";
import RapportVisites from "./pages/Rapport/Visites/RapportVisites";
import RapportVisiteTournee from "./pages/Rapport/VisiteTournee/RapportVisiteTournee";
import ListeRayons from "./pages/Rayons/ListeRayons";
import Reclamations from "./pages/Reclamations/Reclamations";
import InterfaceRelevéPrix from "./pages/relevePrix/InterfaceRelevéPrix";
import RelevePrix from "./pages/relevePrix/RelevePrix";
/* import ListeDepositaire from "./pages/Routings/Depositaire/ListeDepositaire";
 */
import DetailsRoutes from "./pages/Routings/Route/DetailsRoutes";
import ListeRoutes from "./pages/Routings/Route/ListeRoutes";
import ListeRouteCarte from "./pages/Routings/RouteCarte/ListeRouteCarte";
import ShowAllTournee from "./pages/Routings/ShowTournee/ShowAllTournee";
import SuiviRoutings from "./pages/Routings/SuiviRoutings/SuiviRoutings";
import CreationTournee from "./pages/Routings/Tournee/CreationTournee";
import CreationTourneeTrade from "./pages/Routings/Tournee/CreationTourneeTrade";
import DetailsTournee from "./pages/Routings/Tournee/DetailsTournee";
import ListeTournee from "./pages/Routings/Tournee/ListeTournee";
import ValidationTournee from "./pages/Routings/Tournee/ValidationTournee";
import RuptureByMerchPdv from "./pages/Ruptures/DetailsMagasins/RuptureByMerchPdv";
import Listeruptures from "./pages/Ruptures/DetailsRuptures/Listeruptures";
import RuptureAnnuel from "./pages/Ruptures/RuptureAnnuel/RuptureAnnuel";
import ListeStockDepot from "./pages/Stock/stockDepot/ListeStockDepot";
import AjouterUtilisateur from "./pages/Utilisateurs/AddUser/AjouterUtilisateur";
import ListeParametrage from "./pages/Utilisateurs/ListeParametrage";
import ListeUtilisateurs from "./pages/Utilisateurs/ListeUtilisateurs";
import ModifierUtilisateur from "./pages/Utilisateurs/UpdateUser/ModifierUtilisateur";
import Zoning from "./pages/Zoning/Zoning";
import AgenceRoute from "./routes/AgenceRoute";
import AppRoute from "./routes/AppRoute";
import ChefRoute from "./routes/ChefRoute";
import PrivateRoute from "./routes/PrivateRoute";
import SupRoute from "./routes/SupRoute";

function App() {

  return (
    <BrowserRouter>
      <Switch>

        {/**-------------------------------- PAGE Auhtentification ------------------------------- */}
        <AppRoute layout={LoginLayout} component={Login} path="/login" />
        {/**--------------------------------------Dashboard ------------------------------- */}
        <PrivateRoute layout={OthersPages} component={MainPage} exact path="/" />
        <PrivateRoute layout={OthersPages} component={ListeDetailsTournees} exact path="/detailsTourness/:code" />
        {/** ----------------------------------Zoning --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={Zoning} exact path="/zoning" />
        {/** ----------------------------------COMMANDES --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={CommandeGMS} exact path="/commandes&date_debut=:date_debut&date_fin=:date_fin" />
        <PrivateRoute layout={OthersPages} component={DetailsCMD} exact path="/commandes&commande_code=:commande_code&date_debut=:date_debut&date_fin=:date_fin" />
        {/** ---------------------------------- Routings --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeTournee} exact path="/routings/tournee&datedebut=:debut&datefin=:fin" />
        <ChefRoute layout={OthersPages} component={CreationTourneeTrade} exact path="/routings/TourneeZone" />
        <SupRoute layout={OthersPages} component={ValidationTournee} exact path="/routings/validationTournee" />
        <PrivateRoute layout={OthersPages} component={DetailsTournee} exact path="/tournee/code=:code" />
        <PrivateRoute layout={OthersPages} component={CreationTournee} exact path="/routings/routings&route=:route&region=:region" />
        <PrivateRoute layout={OthersPages} component={SuiviRoutings} exact path="/routings/suiviRoutings" />
        <PrivateRoute layout={OthersPages} component={ListeRoutes} exact path="/routings/routes" />
        <PrivateRoute layout={OthersPages} component={DetailsRoutes} exact path="/details_routes/code=:code" />
        <PrivateRoute layout={OthersPages} component={ListeRouteCarte} exact path="/routings/carteRoutes" />
        <PrivateRoute layout={OthersPages} component={ShowAllTournee} exact path="/routings/tournee" />
        {/** ---------------------------------- Produits --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ConfigurationProduits} exact path="/produits/Configurations" />
        <PrivateRoute layout={OthersPages} component={ListeProduits} exact path="/produits/liste" />
        <PrivateRoute layout={OthersPages} component={ListeCadeaux} exact path="/produits/cadeaux" />
        <PrivateRoute layout={OthersPages} component={CodeMETI} exact path="/produits/codeMETI" />
        <PrivateRoute layout={OthersPages} component={PageDLC} exact path="/produits/dlc&datedebut=:debut&datefin=:fin" />
        <PrivateRoute layout={OthersPages} component={PageDN} exact path="/produits/dns&datedebut=:debut&datefin=:fin" />

        <PrivateRoute layout={OthersPages} component={AjouterConventionProduits} exact path="/convention/:code" />
        <PrivateRoute layout={OthersPages} component={ValidationGratuite} exact path="/produits/validationGratuite" />
        <PrivateRoute layout={OthersPages} component={AjouterProduits} exact path="/produits/ajouterProduits" />
        <PrivateRoute layout={OthersPages} component={ModifierProduits} exact path="/produits/modifierProduits&id=:id" />
        <PrivateRoute layout={OthersPages} component={RelevePrix} exact path="/produits/relevePrix&datedebut=:datedebut" />
        <PrivateRoute layout={OthersPages} component={DetailsConventions} exact path="/conventions/code=:code" />
        {/** ---------------------------------- Users --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeParametrage} exact path="/users/parametrage" />
        <PrivateRoute layout={OthersPages} component={CongeInterface} exact path="/users/conge" />
        <PrivateRoute layout={OthersPages} component={AddConge} exact path="/users/addconge" />
        <PrivateRoute layout={OthersPages} component={ListeUtilisateurs} exact path="/users/liste" />
        <PrivateRoute layout={OthersPages} component={DetailsMerch} exact path="/users/details&user_code=:user_code" />
        <PrivateRoute layout={OthersPages} component={AjouterUtilisateur} exact path="/users/ajouterutilisateur" />
        <PrivateRoute layout={OthersPages} component={ModifierUtilisateur} exact path="/users/modifierutilisateur&id=:id" />
        {/** ---------------------------------- Rupture --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={RuptureByMerchPdv} exact path="/ruptures/merch&datedebut=:datedebut&datefin=:datefin&user_code=:user_code" />
        <PrivateRoute layout={OthersPages} component={Listeruptures} exact path="/ruptures/details&datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={RuptureAnnuel} exact path="/ruptures/annuel&annee=:year" />
        {/** ---------------------------------- Magasins --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeMagasins} exact path="/pdv/listePdv" />
        <PrivateRoute layout={OthersPages} component={HistoriqueMagasins} exact path="/pdv/historique&code=:code" />
        <PrivateRoute layout={OthersPages} component={InterfaceHistoriqueImages} exact path="/pdv/historiqueImages&code=:code&annee=:year" />
        <PrivateRoute layout={OthersPages} component={ConfigurationMagasins} exact path="/pdv/configuration" />
        <PrivateRoute layout={OthersPages} component={AjouterPdv} exact path="/pdv/ajouterPDV" />
        <PrivateRoute layout={OthersPages} component={ModifierPDV} exact path="/pdv/modifierPDV&code=:code" />
        <PrivateRoute layout={OthersPages} component={ListeConventions} exact path="/pdv/convention" />
        <PrivateRoute layout={OthersPages} component={AjouterConventiion} exact path="/conventions/ajouterconvention" />
        <PrivateRoute layout={OthersPages} component={AjouterDesConventions} exact path="/conventions/ajouterconventions" />
        <PrivateRoute layout={OthersPages} component={EditConventions} exact path="/editConvention/code=:code" />
        <PrivateRoute layout={OthersPages} component={PlanningCategorie} exact path="/planningPdv/pdv_code=:pdv_code&categorie_code=:categorie_code" />
        <PrivateRoute layout={OthersPages} component={ListeGroupePDV} exact path="/pdv/groupePDV" />
        <PrivateRoute layout={OthersPages} component={DetailsPdv} exact path="/pdv/detailsPdv&code=:code" />
        {/** ---------------------------------- Notes --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeNotes} exact path="/notes/datedebut=:debut&datefin=:fin" />
        {/** ---------------------------------- Notes --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={CreationNote} exact path="/messages/datedebut=:debut&datefin=:fin" />
        <PrivateRoute layout={OthersPages} component={AddNotes} exact path="/addMessages" />
        <PrivateRoute layout={OthersPages} component={EditNote} exact path="/editMessage/:code" />
        {/** ---------------------------------- catalogue --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeCatalogue} exact path="/catalogue/datedebut=:debut&datefin=:fin" />
        {/** ---------------------------------- Stock Depot --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeStockDepot} exact path="/stockdepot" />
        {/** ---------------------------------- PART LINEAIRE --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={PartLineaire} exact path="/partlineaire/datedebut=:debut&datefin=:fin" />
        <PrivateRoute layout={OthersPages} component={PartLineaireAnnuel} exact path="/partlineaireAnnuel/:year" />
        <PrivateRoute layout={OthersPages} component={PartLineaireWeek} exact path="/partlineaireWeek/datedebut=:debut&datefin=:fin" />

        {/** ---------------------------------- Promotions --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={Promotions} exact path="/promotion/datedebut=:debut&datefin=:fin" />
        <PrivateRoute layout={OthersPages} component={ListePromotions} exact path="/planningPromotions" />

        {/** ---------------------------------- RECLAMATIONS --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={Reclamations} exact path="/reclamation/datedebut=:debut&datefin=:fin" />
        {/** ---------------------------------RAYONS --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeRayons} exact path="/rayons/datedebut=:debut&datefin=:fin" />
        <PrivateRoute layout={OthersPages} component={PhotoRayons} exact path="/photorayons/datedebut=:date_debut&datefin=:date_fin" />

        {/** --------------------------------- RAPPORT --------------------------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeRapports} exact path="/rapports/" />
        <PrivateRoute layout={OthersPages} component={EditRapports} exact path="/ParamRapports/" />
        <PrivateRoute layout={OthersPages} component={ListeGraphe} exact path="/rapportsGraphe/datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={RuptureFlower1} exact path="/rapports/rupture1&datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={RapportFlower} exact path="/rapports/rupture2&datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={RapportGestion} exact path="/rapports/gestions&datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={RapportVP} exact path="/rapports/rapport_visite_pointage" />
        <PrivateRoute layout={OthersPages} component={RapportJournalier} exact path="/rapports/rapportJournalier&date=:date&usercode=:user_code" />
        <PrivateRoute layout={OthersPages} component={ListeProduitsRuptures} exact path="/ruptureproduit/rapportJournalier&date=:date&usercode=:user_code&pdv_code=:pdv_code" />
        <PrivateRoute layout={OthersPages} component={DetailsMagasins} exact path="/detailsMagasins/rapportJournalier&date=:date&usercode=:user_code&pdv_code=:pdv_code" />
        <PrivateRoute layout={OthersPages} component={RapportVente} exact path="/rapports/rapportvente&datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={RapportDN} exact path="/rapports/rapportDN&date=:date" />
        <PrivateRoute layout={OthersPages} component={RapportGestionStock} exact path="/rapports/rapportStock&datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={RapportContact} exact path="/rapports/rapportContacts&datedebut=:datedebut&datefin=:datefin&user_code=:user_code" />
        <PrivateRoute layout={OthersPages} component={RapportPartLineaire} exact path="/rapports/rapportPL&datedebut=:datedebut&datefin=:datefin&enseigne=:code" />
        <PrivateRoute layout={OthersPages} component={RapportTableauPL} exact path="/rapports/rapportTableauPL&datedebut=:datedebut&datefin=:datefin" />
        <AgenceRoute layout={OthersPages} component={SuiviPointage} exact path="/rapports/rapportSuiviPointage&datedebut=:datedebut&datefin=:datefin&user_code=:code" />
        <PrivateRoute layout={OthersPages} component={LocalisationPointage} exact path="/rapports/localisationPointage&date=:date&pdv_code=:pdv_code&user_code=:user_code" />
        <PrivateRoute layout={OthersPages} component={ParamétragePointage} exact path="/rapports/parametragePointage" />
        <PrivateRoute layout={OthersPages} component={RapportSuiviTournee} exact path="/rapports/suiviTournee&date=:date" />
        <PrivateRoute layout={OthersPages} component={RapportVisiteMagasins} exact path="/rapports/visitesMagasins&datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={RapportVisites} exact path="/rapports/visites&date=:date" />
        <PrivateRoute layout={OthersPages} component={RapportVisiteTournee} exact path="/rapports/visitesTournees&date=:date" />
        <PrivateRoute layout={OthersPages} component={InterfaceRelevéPrix} exact path="/releve_prix/datedebut=:debut&datefin=:fin" />
        <PrivateRoute layout={OthersPages} component={KPISales} exact path="/rapportKPI/date_debut=:date_debut&date_fin=:date_fin" />
        <PrivateRoute layout={OthersPages} component={RapportStockIssues} exact path="/reportStockIssues/date_debut=:date_debut&date_fin=:date_fin" />
        <PrivateRoute layout={OthersPages} component={RapportMerchandiseur} exact path="/rapport_merchandiseur/date_debut=:date_debut&date_fin=:date_fin" />

        {/**--------------------------------SUIVI ------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={SuiviPL} exact path="/suivi/suiviPL&datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={SuiviReleveprix} exact path="/suivi/suiviRelevePrix&datedebut=:datedebut&datefin=:datefin" />
        <PrivateRoute layout={OthersPages} component={SuiviRupture} exact path="/suivi/suiviRupture&datedebut=:datedebut&datefin=:datefin" />
        {/**--------------------------------Calendrier ------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={Calendrier} exact path="/calendrier" />
        {/**--------------------------------Calendrier ------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeImages} exact path="/images/:date" />
        {/** ---------------------------------- CheckUser --------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={CheckUser} exact path="/checkUser/datedebut=:datedebut&datefin=:datefin" />
        {/**--------------------------------Evaluation ------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={ListeEvaluation} exact path="/QEvaluation" />
        <PrivateRoute layout={OthersPages} component={EvaluationInterface} exact path="/ListeEvaluation" />
        <PrivateRoute layout={OthersPages} component={AutoEvaluation2} exact path="/autoEvaluation" />
        <PrivateRoute layout={OthersPages} component={ResultatAnnuelle} exact path="/EvaluationAnnuelle" />
        {/**--------------------------------Documentation ------------------------------------- */}
        <PrivateRoute layout={OthersPages} component={Documentations} exact path="/documentation" />
        {/**-------------------------------- PAGE 404 ------------------------------- */}
        <AppRoute layout={EmptyLayout} component={PageNotFound} />
      </Switch>

    </BrowserRouter>
  );
}

export default App;
