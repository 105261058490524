/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
//---------------------- TOAST & ALERTE --------------------
import { toast } from 'react-toastify';
//----------------------- WEB SERVICES -----------------------
import { getRelevePrixSociete } from '../../services/Produits/configurationProduits/ConfigurationAPI';
import { getListeRelevePrix, getListeRelevePrixSF } from '../../services/RelevePrix/relevePrixApi';
//------------------------- UTILS -------------------------------
import { SearchItems } from '../../utils/SearchUtils'; //SEARCH FUNCTION
import FiltreSearch from './Components_InterfaceReleve/FiltreSearch';
import TableRelvePrix from './Components_InterfaceReleve/TableRelvePrix';
//--------------------- TRADUCTION --------------------------
import { useTranslation } from 'react-i18next';
import { groupedEnseigne } from '../../services/Ruptures/ruptureAPI';

export default function InterfaceRelevéPrix() {

    //----------- Parametres ----------------
    const { debut, fin } = useParams()
    //------------------ HISTORY ------------
    const history = useHistory();
    //------  FILTRE RAPPORT (DATE DEBUT / DATE FIN ) -----------
    const [dateDebut, setDateDebut] = useState(debut);
    const [dateFin, setDateFin] = useState(fin);
    //-------------- REFERENCES --------------------------
    const inputEl = useRef("");
    //-------------- SEARCH ------------------------------
    const [search, setSearch] = useState("");

    /**
     * LISTE RELEVE PRIX 
     * Liste : init results du releve Prix
     * Searchliste : le resultat de la recherche
     * saveListe : sauvegarde de la liste init releve Prix pour la récupération 
     */
    const [liste, setliste] = useState([])
    const [saveList, setSaveList] = useState([]);
    const [searchList, setSearchlist] = useState([]);
    /**
     * LISTE ENSEIGNES 
     */
    const [listeEnseignes, setlisteEnseignes] = useState([])
    const [codeEnseigne, setcodeEnseigne] = useState("")

    /**
     * VALUE OF RELEVE PRIX FROM SOCIETE PRINCIPALE 
     */
    const [releve, setreleve] = useState("")
    //-------------------- CURRENT LISTE --------------------
    const currentListe = search.length < 1
        ? liste
        : searchList
    //------------- Traduction ---------------
    // eslint-disable-next-line no-unused-vars
    const [t, i18n] = useTranslation("global");
    //--------- LOADER ------------------
    const [loading, setloading] = useState(true)


    /**
     * FUNCTION MAIN TRAITEMENT : CALL WEB SERVICES 
     */
    const MainTraitement = () => {
        
        getRelevePrixSociete()
            .then((result) => {
                if (result?.data?.success === 1) {
                    setreleve(result?.data?.data[0]?.releve_prix);
                    var type = result?.data?.data[0]?.releve_prix
                    if (type === "produit") {
                        /**
                         * WEB SERVICE : RETURN LISTE DES RELEVE PRIX AVEC PRODUIT SEULEMENT
                         */
                        getListeRelevePrix(dateDebut, dateFin,codeEnseigne)
                            .then((result) => {
                                if (result?.data?.success === 1) {
                                    setliste(result?.data?.liste)
                                    setSaveList(result?.data.liste);
                                }
                            }).catch((err) => {
                                console.log(err)
                            }).finally(() => {
                                setloading(false)
                            })
                    } else if(type === "sousfamille") {
                        /**
                         * WEB SERVICE : RETURN LISTE DES RELEVE PRIX AVEC SOUS FAMILLE SEULEMENT
                         */
                        getListeRelevePrixSF(dateDebut, dateFin,codeEnseigne)
                            .then((result) => {
                                if (result?.data?.success === 1) {
                                    setliste(result?.data?.liste)
                                    setSaveList(result?.data.liste);
                                }
                            }).catch((err) => {
                                console.log(err)
                            }).finally(() => {
                                setloading(false)
                            })
                    }
                }
            }).catch((err) => {
                console.log(err)
            });
    }

    useEffect(() => {
        groupedEnseigne()
        .then((result) => {
            setlisteEnseignes(result?.data?.data)
        }).catch((err) => {
            console.log(err)
        });
        MainTraitement()

    }, [])

    /**
     * FUNCTION : RECHERCHE D'UN ELEMENT DANS LE TABLEAU
     */
    const getSearchTerm = () => {
        setSearch(inputEl.current.value);
        if (inputEl.current.value !== "") {
            const newList = SearchItems(liste, search);
            setSearchlist(newList);
        } else {
            setliste(saveList);
        }
    };

    /**
     * FUNCTION : RECHERCHE PAR DATE DEBUT / DATE FIN dans le releve Prix
     */
    const onSearchData = () => {
        setloading(true)
        /**
         * VERIFIER ET COMPARER LA DATE DEBUT / DATE FIN
         */
        if (dateDebut !== "" && dateFin !== "") {
            if (dateDebut <= dateFin) {
                setloading(true)
                /**
                 * CHANGE L URL PAR HISTORY PUSH 
                 */
                history.push({
                    pathname: "/releve_prix/datedebut=" + dateDebut + "&datefin=" + dateFin
                })
                /**
                 * GET LISTE RELEVE PRIX PAR DATE DEBUT / DATE FIN 
                 */
                MainTraitement()
            } else if (dateFin < dateDebut) {
                toast(t('messages.dateinf'), { type: "warning", position: "top-right" })
            }
        }
    }
    /**
     * FUNCTION : ECRASER LISTE PAR LA SAUVEGARDE saveListe
     */
    const onClickBack = () => {
        setliste(saveList)
    }
    return (
        <div>
            {/** --------------------------  PAGE HEADER  ------------------------- */}
            <div className="page-header">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><i className="fe fe-life-buoy mr-1" /> {t('relevePrix.relevePrix')} </li>
                    <li className="breadcrumb-item active" aria-current="page"> {t('relevePrix.listeRelevePrix')}</li>
                </ol>
            </div>
            {/** ---------------------- SECTION  : RECHERCHE  --------------------- */}
            <FiltreSearch dateDebut={dateDebut} setDateDebut={setDateDebut} dateFin={dateFin} setDateFin={setDateFin} onSearchData={onSearchData} onClickBack={onClickBack} codeEnseigne={codeEnseigne}  setcodeEnseigne={setcodeEnseigne} listeEnseigne={listeEnseignes} t={t} />
            {/** --------------------- CONTENUE DE LA PAGE ------------------------ */}
            <TableRelvePrix inputEl={inputEl} search={search} getSearchTerm={getSearchTerm} t={t} liste={liste} loading={loading} currentListe={currentListe} releve={releve} />
        </div>
    )
}
